
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const key = ref<string>('');
    const loading = ref<boolean>(true);
    const hasError = ref<boolean>(false);

    //Form submit function
    const verifyEmail = (key) => {

      loading.value = true;

      store
          .dispatch(Actions.VERIFY_EMAIL, key)
          .then(() => {

            loading.value = false;

            Swal.fire({
              title: "Vérification de l'e-mail",
              html: "Verification de l'email terminée!<br> Vous allez être redirigé vers la page de connexion.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continuer",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            loading.value = false;
            hasError.value = true;
            // Alert then login failed
            Swal.fire({
              title: "Vérification de l'e-mail",
              text: "Cet e-mail n'a pas été vérifié",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };

    const goToHome = () => {
      router.push({ name: "home" });
    }

    onMounted(() => {

      loading.value = true;

      key.value = route.params.key as string;

      setTimeout(() => {
        verifyEmail(key.value);
      }, 1000);
      
    });

    return {
      verifyEmail,
      loading,
      goToHome,
      hasError
    };
  },
});
